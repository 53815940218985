import React, { ReactElement } from 'react';


interface TooltipProps {
  children: ReactElement;
  text: string;
}

const Tooltip = (props: TooltipProps) => (
  <div className="tooltip">
    {props.children}
    {props.text !== '' && <span className="tooltiptext">{props.text}</span>}
  </div>
);

export default Tooltip;
