import React from 'react';


interface SongProps {
  youtubeVideoId: string;
}

const Song = (props: SongProps) => (
  <div className="flex justify-center align-center">
    <div className="h-64 lg:h-80 lg:w-128">
      <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${props.youtubeVideoId}`} />
    </div>
  </div>
);

export default Song;
