import React from 'react';
import Socials, { SocialLink } from 'components/Socials';

import ArrowDown from 'assets/images/arrow-down-solid.svg';
import { useScrollIntoView } from 'utils/index';


interface ISlide {
  backgroundImg: string;
  youtubeVideoId: string;
  title: string;
  socialLinks: SocialLink[];
}

interface SlideProps extends ISlide {
  keyPrefix: string;
  nextElementId: string;
}

const Slide = (props: SlideProps) => {
  const { scrollIntoView } = useScrollIntoView(props.nextElementId);

  return (
    <div
      className="relative w-full h-screen bg-cover bg-center bg-no-repeat"
      style={{backgroundImage: `url('${props.backgroundImg}')`}}
    >
      <div
        className="absolute top-0 left-0 bottom-0 right-0"
        style={{backgroundColor: 'rgba(0, 0, 0, .35)'}}
      />
      <div className="absolute top-0 w-full h-screen flex items-center">
        <div className="container mx-auto text-white flex justify-center">
          <div className="flex flex-1 flex-col-reverse lg:flex-row lg:space-x-8">
            <div className="flex flex-1 self-center mt-4 lg:hidden">
              <Socials
                links={props.socialLinks}
                keyPrefix={props.keyPrefix}
              />
            </div>
            <div className="flex flex-1 justify-end self-center">
              <div className="h-64 lg:h-80 lg:w-128">
                <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${props.youtubeVideoId}`} />
              </div>
            </div>
            <div className="flex flex-1 self-center text-center mb-4 lg:text-left lg:mb-0">
              <div className="flex flex-col lg:space-y-4">
                <div className="text-5xl lg:text-6xl leading-none mb-4 lg:mb-0">
                  {props.title}
                </div>
                <div className="hidden lg:block">
                  <Socials
                    links={props.socialLinks}
                    keyPrefix={props.keyPrefix}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 flex w-full justify-center pb-4 opacity-50">
        <img
          className="h-8 w-8 cursor-pointer pulse"
          onClick={scrollIntoView}
          src={ArrowDown}
          alt=""
        />
      </div>
    </div>
  );
};

export default Slide;
export {
  SlideProps,
  ISlide
}
