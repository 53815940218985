import React, { ReactElement } from 'react';
import Tooltip from 'components/Tooltip';

import Amazon from 'assets/images/amazon.svg';
import Instagram from 'assets/images/instagram.svg';
import ITunes from 'assets/images/itunes.svg';
import Mail from 'assets/images/mail.svg';
import Spotify from 'assets/images/spotify.svg';
import Youtube from 'assets/images/youtube.svg';


type IconType = 'amazon' | 'instagram' | 'itunes' | 'email' | 'spotify' | 'youtube';

const icons: { [key in IconType]: ReactElement } = {
  'amazon': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Amazon} alt="" />,
  'instagram': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Instagram} alt="" />,
  'itunes': <img className="h-6 w-6 lg:h-8 lg:w-8" src={ITunes} alt="" />,
  'email': <></>,
  'spotify': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Spotify} alt="" />,
  'youtube': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Youtube} alt="" />
};

interface SocialLink {
  type: IconType;
  url: string;
  tooltip?: string;
}

interface SocialsProps {
  links: SocialLink[];
  keyPrefix: string;
}

const Socials = (props: SocialsProps) => {
  const email = props.links.find((link) => link.type === 'email');

  return (
    <>
      <div className="space-x-4 pt-2 pl-2 pr-2 bg-gray-100 bg-opacity-10 rounded">
        {props.links.map((link, index) => {
          if (link.type !== 'email') {
            return (
              <a
                key={`social-icon-${props.keyPrefix}-${index}`} href={link.url}
                target="_blank"
                rel="noopener norefferer"
              >
                <Tooltip text={link.tooltip || ''}>
                  {icons[link.type]}
                </Tooltip>
              </a>
            );
          }
        })}
      </div>

      {email && (
        <a href={email.url} className="pt-2 pl-2 pr-2 bg-gray-100 bg-opacity-10 rounded flex align-items-center">
          <img className="h-6 w-6 lg:h-8 lg:w-8 pr-2" src={Mail} alt="" />
          <span className="pt-1">{email.url.replace('mailto:', '')}</span>
        </a>
      )}
    </>
  );
};

export default Socials;
export {
  SocialsProps,
  SocialLink,
  IconType
}
