import React from 'react';
import Socials, { SocialLink } from 'components/Socials';


interface ProfileProps {
  name: string;
  description: string;
  pictureUrl: string;
  socialLinks: SocialLink[];
}

const Profile = (props: ProfileProps) => (
  <div className="flex flex-col space-y-4 my-4 p-8 text-center lg:flex-row lg:space-y-0 lg:space-x-8 lg:text-left">
    <div className="flex flex-1 justify-end self-center">
      <img
        className="rounded-full object-cover object-center w-32 h-32 lg:w-64 lg:h-64"
        src={props.pictureUrl}
        alt={props.name}
      />
    </div>
    <div className="flex flex-1 flex-col self-center space-y-2 lg:space-y-4">
      <div className="lg:hidden">
        <Socials
          keyPrefix="profile"
          links={props.socialLinks}
        />
      </div>
      <div className="whitespace-pre-line">
        {props.description}
      </div>
      <div className="hidden lg:block">
        <Socials
          keyPrefix="profile"
          links={props.socialLinks}
        />
      </div>
    </div>
  </div>
);

export default Profile;
export {
  ProfileProps
}
