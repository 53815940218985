import React from 'react';
import { useRouteData } from 'react-static';
import Footer from 'components/Footer';
import Header from 'components/Header';

import pascalhelmerLogo from 'assets/images/pascalhelmer.png';


const Legal = () => {
  const {
    contact,
    legal
  } = useRouteData();

  return (
    <div>
      <Header elementId="footer" text={contact.name} defaultBlack={true} />

      <section className="w-full h-full flex justify-center items-center my-32 flex-col space-y-8 p-2">
        <img className="h-40 lg:h-80 rounded object-cover object-center" src={contact.avatarImg} alt={contact.name} />

        <p className="whitespace-pre">
          {legal.contact}
        </p>

        <a
          className="absolute bottom-20 cursor-pointer lg:bottom-24"
          href="https://pascalhelmer.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="h-16 lg:h-32"
            src={pascalhelmerLogo}
            alt="Pascal Helmer"
          />
        </a>
      </section>

      <div className="absolute bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Legal;
