import React from 'react';
import { useLocation, useNavigate } from '@reach/router';
import classNames from 'classnames';
import { useInView, useScrollIntoView } from 'utils/index';


interface HeaderProps {
  elementId: string;
  text: string;
  firstSectionId?: string;
  defaultBlack?: boolean;
}

const Header = (props: HeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { inView } = useInView(props.elementId);
  const { scrollIntoView } = useScrollIntoView(props.firstSectionId);

  const handleClick = (event: any) => {
    if (location.pathname === '/') {
      scrollIntoView(event);
    } else {
      navigate('/').then(() => undefined);
    }
  };

  return (
    <div
      className={classNames(
      'fixed top-0 w-full text-white z-10 background-color-transition',
        (inView || props.defaultBlack) ? 'bg-black' : 'bg-gray-100 bg-opacity-10 shadow-sm'
      )}
    >
      <div className="container mx-auto flex items-center h-16 justify-center lg:justify-start lg:h-20">
        <span className="uppercase text-xl cursor-pointer" onClick={handleClick}>{props.text}</span>
      </div>
    </div>
  );
};

export default Header;
export {
  HeaderProps
}
