

  
// Template Map
export default {
  '/builds/helmer-ug/marius-website/src/pages/404.tsx': require('/builds/helmer-ug/marius-website/src/pages/404.tsx').default,
'/builds/helmer-ug/marius-website/src/pages/index.tsx': require('/builds/helmer-ug/marius-website/src/pages/index.tsx').default,
'/builds/helmer-ug/marius-website/src/pages/legal.tsx': require('/builds/helmer-ug/marius-website/src/pages/legal.tsx').default
}

export const notFoundTemplate = '/builds/helmer-ug/marius-website/src/pages/404.tsx'

