import React from 'react';
import { Link } from '@reach/router';


const Footer = () => (
  <div id="footer" className="w-full bg-black text-white">
    <nav className="container mx-auto h-16 lg:h-20 flex flex-col lg:flex-row items-center justify-center lg:justify-between">
      <Link to="/legal">Legal</Link>

      <div className="text-xs">
        ©2020 Marius Schönwetter
      </div>
    </nav>
  </div>
);

export default Footer;
