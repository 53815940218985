import { useEffect, useState } from 'react';
import isElementInViewport from 'utils/isElementInViewport';


const useInView = (
  elementId: string
) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const updateShow = () => {
      const elem = document.getElementById(elementId);
      if (elem) {
        setInView(isElementInViewport(elem));
      }
    };

    window.addEventListener('scroll', updateShow);
    window.addEventListener('touchmove', updateShow);
    window.addEventListener('resize', updateShow);

    return function cleanup() {
      window.removeEventListener('scroll', updateShow);
      window.removeEventListener('touchmove', updateShow);
      window.removeEventListener('resize', updateShow);
    };
  }, [inView, setInView, elementId]);

  return {
    inView
  }
};

export default useInView;
