import { useEffect, useState } from 'react';


const useScrollIntoView = (
  elementId: string
) => {
  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    setAnchorTarget(document.getElementById(elementId));
  }, [elementId]);

  const scrollIntoView = (event: any) => {
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return {
    scrollIntoView
  }
};

export default useScrollIntoView;
