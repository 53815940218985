import React from 'react';
import { useRouteData } from 'react-static';
import Header from 'components/Header';
import Slide, { ISlide } from 'components/Slide';
import Profile from 'components/Profile';
import Footer from 'components/Footer';


const Index = () => {
  const {
    slides,
    contact
  } = useRouteData();

  return (
    <>
      <Header elementId="footer" text={contact.name} firstSectionId="section-0" />

      {slides.map((slide: ISlide, index: number) => (
        <section key={`slide-${index}`} id={`section-${index}`} className="w-full h-screen">
          <Slide
            backgroundImg={slide.backgroundImg}
            youtubeVideoId={slide.youtubeVideoId}
            title={slide.title}
            socialLinks={slide.socialLinks}
            keyPrefix={`slide-${index}`}
            nextElementId={index+1 !== slides.length ? `section-${index+1}` : 'footer'}
          />
        </section>
      ))}

      <section id={`section-${slides.length}`} className="container mx-auto flex self-center lg:h-screen">
        <Profile
          name={contact.name}
          description={contact.description}
          pictureUrl={contact.avatarImg}
          socialLinks={contact.socialLinks}
        />
      </section>

      <Footer />
    </>
  );
};

export default Index;
