import React from 'react';
import { useRouteData } from 'react-static';
import { Link } from '@reach/router';
import Song from 'components/Song';


const ErrorPage = () => {
  const { song } = useRouteData();

  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <h1 className="text-6xl">404</h1>
      <p className="text-xs lg:text-base text-center whitespace-pre-line mb-8">
        Unfortunately we couldn't find what you're looking for.<br/>
        {song && <>But you could listen to one of my songs until we find a way back together.</>}
      </p>

      {song && <Song youtubeVideoId={song.youtubeVideoId} />}

      <Link className="mt-4 underline" to="/">Return</Link>
    </div>
  );
};

export default ErrorPage;
