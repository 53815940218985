import React from 'react';


const Loading = () => (
  <div className="absolute w-screen h-screen flex uppercase justify-center items-center">
    Marius Schönwetter
  </div>
);

export default Loading;
